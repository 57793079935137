import React from 'react';
import Typed from 'react-typed';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import AboutOne from '../elements/about/AboutOne';
import AboutSeven from '../elements/about/AboutSeven';
import AboutEight from '../elements/about/AboutEight';
import Technology from '../elements/about/Technology'
import ServiceOne from '../elements/service/ServiceOne';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import TestimonialOne from "../elements/testimonial/TestimonialOne";
import Clients from '../elements/testimonial/Clients';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";




const bitfrillsHomepage = () => {
    return (
        <>
        <SEO title="BitFrills | Web Development Agency | 2023 | Canada" />
            <main className="page-wrapper">

                <HeaderOne btnStyle="round" HeaderSTyle="header-not-transparent" />
                 {/* Start Slider Area  */}
                 <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-left">
                                <span className="subtitle">DIGITAL CONSULTING AGENCY</span>
                                    <h1 className="title theme-gradient display-two">
                                    <span>
                                        <Typed
                                            strings={[
                                            "We create dreams into reality",
                                            "We develop ideas into products",                                         
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={20}
                                            backDelay={1000}
                                            loop
                                        />
                                        </span>
                                    </h1>
                                    <p className="description">Bitfrills Solutions is a full-service web agency that works to build websites, web apps, e-Commerce solutions and much more for our clients around the globe.</p>
                                    <div className="button-group">                                      
                                        <Link className="main-btn btn-default btn-medium btn-border round btn-icon"  to="/contact" title="START HERE!">GOT AN IDEA? START HERE!<i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 order-1 order-lg-2">
                            <div className='animated-anim'>

                            </div>
                                <div className="thumbnail">
                                    <img src="./images/bg/web-design.png" className="img-fluid" alt="web-development" title="Web Development" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                {/* Start Trusted client */}
                    <div className="client-area client-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Trusted by 1000+ companies!"
                                        description = "We have more than 1000+ trusted clients around the World." />
                                </div>
                            </div>
                            <Clients />
                        </div>
                    </div>
                {/* End Trusted client */}

                 <Separator />

              {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we can do for you"
                                    title = "Answer to all your e-Commerce and Web Development needs"
                                    description = "Our goal is to help our clients beyond their expectations." />
                            </div>
                        </div>
                        <ServiceOne serviceStyle = "gallery-style service__style--1" textAlign = "text-left" />

                    </div>
                </div>
               {/* End Service Area  */}

                <Separator /> 

                {/* start showing swatch color */}
                    <AboutSeven />
                {/* end showing swatch color */}

                <Separator /> 

             {/* Start Technology Area  */}
                <Technology/>                            
             {/* End Technology Area  */}
             <Separator /> 

                {/* Start Call To Action Area  */}
                <div className="rwt-callto-action-area rn-section-gap">
                    <div className="wrapper">
                        <CalltoActionFive />
                    </div>
                </div>
                {/* End Call To Action Area  */}
                <Separator /> 

                {/* Start awesome website section  */}
                   <AboutOne />                            
                {/* End awesome website section  */}

                <Separator /> 
                {/* Start Mission Area   */}
                {/* <Mission />    */}
                 <AboutEight/>                   
                {/* Start Mission Area  */}


       {/* Start Testimonial Area  */}
       <div className="testimonial-section rwt-testimonial-area rn-section-gap ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Client Feedback"
                                    title = "Client’s review for our work satisfaction."
                                    description = "Valuable feedback from clients on their satisfaction with our work"
                                />
                            </div>
                        </div>
                        <TestimonialOne teamStyle="" />
                    </div>
                </div>
            {/* End Testimonial Area  */}

                {/* <Separator />    */}
                
                <FooterTwo />

                <Copyright />
            </main>
        </>
    )
}
export default bitfrillsHomepage;
