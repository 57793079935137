import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck, FiStar } from "react-icons/fi";

const AboutTwo = () => {
    return (
        <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                <div className="col-lg-5 col-md-6 col-12 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">
                                    <h2 className="title mt--10">Technologies that we work with</h2>
                                    <p>At Bitfrills Solutions, we work with the modern technology of web development and do not use outdated methods of development.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6 col-12">
                    <div className="row text-center">
                       <div className="col-lg-3 col-md-3 col-6 mt_md--30 mt_sm--30">
                          <div className="thumbnail-tech">
                            <img src="./images/about/react.jpg" alt="React" title="React Js" />
                          </div>
                       </div>
                       <div className="col-lg-3 col-md-3 col-6 mt_md--30 mt_sm--30">
                          <div className="thumbnail-tech">
                            <img src="./images/about/node.jpg" alt="node" title="Node Js" />
                          </div>
                       </div>
                       <div className="col-lg-3 col-md-3 col-6 mt_md--30 mt_sm--30">
                          <div className="thumbnail-tech">
                            <img src="./images/about/php.png" alt="php" title="PHP" />
                          </div>
                       </div>
                       <div className="col-lg-3 col-md-3 col-6 mt_md--30 mt_sm--30">
                          <div className="thumbnail-tech">
                            <img src="./images/about/aws.jpg" alt="aws" title="aws" />
                          </div>
                       </div>                      
                      </div>
                     <div className="row text-center mt--10">
                       <div className="col-lg-3 col-md-3 col-6 mt_md--30 mt_sm--30">
                          <div className="thumbnail-tech">
                            <img src="./images/about/Wordpress.jpg" alt="Wordpress" title="Wordpress" />
                          </div>
                       </div>
                       <div className="col-lg-3 col-md-3 col-6 mt_md--30 mt_sm--30">
                          <div className="thumbnail-tech">
                            <img src="./images/about/Shopify.jpg" alt="Shopify" title="Shopify" />
                          </div>
                       </div>
                       <div className="col-lg-3 col-md-3 col-6 mt_md--30 mt_sm--30">
                          <div className="thumbnail-tech">
                            <img src="./images/about/rails.jpg" alt="Ruby Rails" title="Ruby Rails"/>
                          </div>
                       </div>
                       <div className="col-lg-3 col-md-3 col-6 mt_md--30 mt_sm--30">
                          <div className="thumbnail-tech">
                            <img src="./images/about/graphql.jpg" alt="graphql" title="Graph QL"/>
                          </div>
                       </div>                     
                     </div>
                    </div>                  
                </div>
            </div>   
        </div>
    )
}

export default AboutTwo;
