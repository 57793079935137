import React, { useState, useEffect } from "react";
import axios from "axios";

function ContactForm({ props, formStyle }) {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  // useEffect(() => {
  //   Axios.get("http://localhost:3000/read").then((response) => {
  //     console.log("this is responce",response);
  //     console.log("this is responce data",response.data);
  //   });
  // }, []);

  // const sendmail = () => {
  //   Axios.post("https://bitfrills.com/mail", {
  //     fullName: fullName,
  //     email: email,
  //     number: number,
  //     subject: subject,
  //     message: message
  //   }).then((response) => {
  //     console.log("response", response);
  //     if (response.data.msg === "success") {
  //       alert("Email sent, awesome!");
  //     } else if (response.data.msg === "fail") {
  //       alert("Oops, something went wrong. Try again");
  //     }
  //   });
  // };
  const sendmail = () => {
    const data = {
      fullName: fullName,
      email: email,
      number: number,
      subject: subject,
      message: message,
      website: "www.bitfrills.com"
    };
  
    axios.post('http://localhost:3002/contact', data)
      .then(response => {
        console.log('Response:', response.data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };
  
  
  
  
  return (
    <form onSubmit={sendmail}>
      <div className="form-group">
        <input type="text" name="fullname" onChange={(e)=>{setFullName(e.target.value)}} placeholder="Your Name" required />
      </div>

      <div className="form-group">
        <input type="email" name="email" onChange={(e)=>{setEmail(e.target.value)}} placeholder="Email Address" required />
      </div>

      <div className="form-group">
        <input type="text" name="number" onChange={(e)=>{setNumber(e.target.value)}} placeholder="Phone Number" required />
      </div>

      <div className="form-group">
        <input type="text" name="subject" onChange={(e)=>{setSubject(e.target.value)}} placeholder="Subject" required />
      </div>

      <div className="form-group">
        <textarea name="message" onChange={(e)=>{setMessage(e.target.value)}} placeholder="Your Message" required></textarea>
      </div>
      
      <div className="form-group">
        <button className="btn-default btn-large">Submit Now</button>
      </div>
    </form>
  );
}
export default ContactForm;
